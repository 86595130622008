import React, { createContext, useEffect, useState } from "react";
import { presaleAbi, bnbVaultAbi, ethVaultAbi, usdtAbi } from "./abi"
import { useAccount, useChainId, useBalance } from "wagmi";
import { presaleAddress, bnbVaultAddress, ethVaultAddress, ethUsdtAddress, bnbUsdtAddress, tokenAddress } from "./environment"
export const getNetworkContract = (networkID) => {
  switch (networkID) {
    case 56:
      return {
        bnbVaultAbi: bnbVaultAbi,
        bnbVaultAddress: bnbVaultAddress,
        bnbUsdtAbi: usdtAbi,
        bnbUsdtAddress: bnbUsdtAddress,
        presaleAddress: presaleAddress,
        presaleAbi: presaleAbi,
        chainId: 56,
      };
    case 1:
      return {
        presaleAbi: presaleAbi,
        presaleAddress: presaleAddress,
        ethVaultAbi: ethVaultAbi,
        ethVaultAddress: ethVaultAddress,
        ethUsdtAbi: usdtAbi,
        ethUsdtAddress: ethUsdtAddress,
        tokenAddress: tokenAddress,
        chainId: 1,
      };
    default:
      return {
        presaleAbi: presaleAbi,
        presaleAddress: presaleAddress,
        ethVaultAbi: ethVaultAbi,
        ethVaultAddress: ethVaultAddress,
        ethUsdtAbi: usdtAbi,
        ethUsdtAddress: ethUsdtAddress,
        tokenAddress: tokenAddress,
        chainId: 1
      }
  }
}
let initialState = {
  account: null,
  chainId: 1,
  // chainId: 56,
};
export const AppContext = createContext(initialState);



export const ConectivityProvider = ({
  children,
}) => {
  const { address, isConnected } = useAccount();
  // const { chains, chain } = useNetwork();
  const chainId = useChainId();
  // const chainIds = chains?.map((info) => info?.id);

  const [state, setState] = useState(initialState);

  useEffect(() => {
    setState({
      account: address ?? null,
    });
  }, [isConnected, address]);
  return (
    <AppContext.Provider
      value={{
        account: state.account,
        chainId: chainId,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
import React from "react";

const CountItem = (props) => {
  return (
    <div className="min-w-[78px] px-[10px] py-[10px] flex flex-col justify-center items-center rounded-[.5rem] text-center ">
      <p className="text-white text-[13px] md:text-[14px] font-semibold leading-[30px] ">{props.title}</p>
      <h1 className="text-white text-[28px] font-semibold leading-1 ">
            {String(props.value).padStart(2, "0")}
      </h1>
      
    </div>
  );
};

export default CountItem;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { WagmiProvider } from 'wagmi';
import { darkTheme, RainbowKitProvider } from '@rainbow-me/rainbowkit';
import '@rainbow-me/rainbowkit/styles.css';
import { sepolia, mainnet, bscTestnet, bsc } from 'wagmi/chains';
import { config } from './wagmi';
import { ConectivityProvider } from './components/context/utils';
import { createWeb3Modal } from '@web3modal/wagmi/react'
import { defaultWagmiConfig } from '@web3modal/wagmi/react/config'
import { AppKitProvider } from './connectWallet';
import { toast, ToastContainer } from "react-toastify";
const root = ReactDOM.createRoot(document.getElementById('root'));

const queryClient = new QueryClient();
const metadata = {
  name: 'Forg',
  description: 'AppKit Example',
  url: 'https://pepeforg.com/', // origin must match your domain & subdomain
  icons: ['https://avatars.githubusercontent.com/u/37784886']
}
// const config = defaultWagmiConfig({
//   chains:[sepolia, mainnet, bscTestnet, bsc],
//   projectId:"2d8973a655e87af1d1aada0e927e2510",
//   metadata, // Optional - Override createConfig parameters
// })
createWeb3Modal({
  metadata,
  wagmiConfig: config,
  projectId:"2d8973a655e87af1d1aada0e927e2510",
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
  enableOnramp: true
})
root.render(
  <React.StrictMode>
    <WagmiProvider config={config}>
    {/* <AppKitProvider> */}
      <ConectivityProvider>


        <QueryClientProvider client={queryClient}>
          <RainbowKitProvider modalSize='compact' theme={darkTheme({
            accentColor: '#7b3fe4',
            accentColorForeground: 'white',
            borderRadius: 'small',
            fontStack: 'system',
            overlayBlur: 'small'
          })} >
            <App />
            <ToastContainer autoClose={3000} draggableDirection="x"/>
          </RainbowKitProvider>
        </QueryClientProvider>
      </ConectivityProvider>
    </WagmiProvider>
    {/* </AppKitProvider> */}

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

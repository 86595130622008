import { getDefaultConfig } from '@rainbow-me/rainbowkit';
import { mainnet, bscTestnet, bsc } from 'wagmi/chains';
import { coinbaseWallet, metaMask,walletConnect } from 'wagmi/connectors'


export const sepolia = {
  id: 11_155_111,
  name: 'Sepolia',
  nativeCurrency: { name: 'Sepolia Ether', symbol: 'ETH', decimals: 18 },
  rpcUrls: {
    default: {
      http: ['https://endpoints.omniatech.io/v1/eth/sepolia/public'],
    },
  },
  blockExplorers: {
    default: {
      name: 'Etherscan',
      url: 'https://sepolia.etherscan.io',
      apiUrl: 'https://api-sepolia.etherscan.io/api',
    },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 751532,
    },
    ensRegistry: { address: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e' },
    ensUniversalResolver: {
      address: '0xc8Af999e38273D658BE1b921b88A9Ddf005769cC',
      blockCreated: 5_317_080,
    },
  },
  testnet: true,
}
export const config = getDefaultConfig({
  autoConnect: false,
  appName: 'pepefrog',
  projectId: '2d8973a655e87af1d1aada0e927e2510',
  chains: [sepolia, mainnet, bscTestnet, bsc],
  connectors: [
    new metaMask({ chains: [sepolia, mainnet, bscTestnet, bsc] }),
    new coinbaseWallet({
      chains: [sepolia, mainnet, bscTestnet, bsc],
      options: {
        appName: 'pepf',
      },
    }),
    walletConnect({
      chains: [sepolia, mainnet, bscTestnet, bsc],
      projectId: "2d8973a655e87af1d1aada0e927e2510",
      options: {
        qrcode: true,
        projectId: "2d8973a655e87af1d1aada0e927e2510",
        name: 'pepf',
        relayUrl: 'wss://relay.walletconnect.org'
      },
    }),
  ],
});

import { createWeb3Modal } from '@web3modal/wagmi/react'
import { defaultWagmiConfig } from '@web3modal/wagmi/react/config'

import { WagmiProvider, createConfig, http } from 'wagmi'
import { sepolia, mainnet, bscTestnet, bsc  } from 'wagmi/chains'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
// 0. Setup queryClient
const queryClient = new QueryClient()

// 1. Get projectId from https://cloud.walletconnect.com
const projectId = '2d8973a655e87af1d1aada0e927e2510'

// 2. Create wagmiConfig
const metadata = {
  name: 'AppKit',
  description: 'AppKit Example',
  url: 'https://pepeforg.com', // origin must match your domain & subdomain
  icons: ['https://avatars.githubusercontent.com/u/37784886']
}

const chains = [sepolia, mainnet, bscTestnet, bsc ]
const config1 = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
})


const config = createConfig({
  chains: [mainnet, sepolia, bscTestnet, bsc],
  transports: {
    [mainnet.id]: http('https://mainnet.infura.io/v3/'),
    // [sepolia.id]: http('https://endpoints.omniatech.io/v1/eth/sepolia/public'),
    [bsc.id]: http('https://bsc-dataseed.binance.org/'),
    // [bscTestnet.id]: http('https://endpoints.omniatech.io/v1/bsc/testnet/public'),
  },
  projectId,
  metadata,
})



// 3. Create modal
createWeb3Modal({
  metadata,
  wagmiConfig: config,
  projectId,
  enableAnalytics: true // Optional - defaults to your Cloud configuration
})

export function AppKitProvider({ children }) {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </WagmiProvider>
  )
}